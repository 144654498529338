import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.placeholder = "0,000000"
    new Cleave(this.element, {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralDecimalScale: 6
    })
  }

}
